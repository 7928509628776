import * as React from "react";
import { ClientLogger } from "bernie-client";
import { SystemEvent, SystemEventLevel } from "bernie-logger";
import { Field } from "uitk-react-form";
import { useExperiment } from "@shared-ui/experiment-context";
import { PrefetchProductType, usePrefetchAssets } from "components/utility/usePrefetchAssets";

export const prefetchExperimentMatcher = {
  [PrefetchProductType.FLIGHTS_SRP]: "Prefetching_Flights_SRP_javascript_bundles_from_homepage",
  [PrefetchProductType.LODGING_SRP]: "Prefetching_Lodging_SRP_javascript_bundles_from_homepage",
};

export const shouldPrefetchOnFieldChange = (productType: PrefetchProductType, field: Field): boolean => {
  switch (productType) {
    case PrefetchProductType.FLIGHTS_SRP:
      return field?.name === "destination_select";
    case PrefetchProductType.LODGING_SRP:
      return field?.name === "destination_form_field";
    default:
      return false;
  }
};

export const useSRPPrefetchAssetsExperiment = (productType: PrefetchProductType) => {
  const { exposure, logExperiment } = useExperiment(prefetchExperimentMatcher[productType]);
  const isFlight = productType === PrefetchProductType.FLIGHTS_SRP;
  const { prefetch } = usePrefetchAssets(productType, (response) => (isFlight ? response : response.srp));
  const logger = ClientLogger.getLoggerWithIdentifier("processFlightsSearchPrefetch");

  const prefetchSRPAssets = React.useCallback(
    (field: Field) => {
      if (prefetchExperimentMatcher[productType] && shouldPrefetchOnFieldChange(productType, field)) {
        try {
          logExperiment(exposure);
        } catch (error) {
          logger.logEvent(new SystemEvent(SystemEventLevel.ERROR, "ENVERROR"), error);
        }
        if (exposure.bucket === 1) {
          prefetch();
        }
      }
    },
    [logExperiment, prefetch, exposure]
  );

  return {
    prefetchSRPAssets,
  };
};
