import * as React from "react";
import { useExperiment } from "@shared-ui/experiment-context";
import { Field } from "uitk-react-form";
import { usePreloadPropertySearchMutation } from "./usePreloadPropertySearchMutation";
import { useBexApi } from "@shared-ui/bex-api-context";
import { getPropertySearchCriteriaFromQueryParams } from "@shared-ui/retail-search-tools-lodging";
import { PrefetchSearchResultsMutationVariables } from "__generated__/typedefs";
import { ClientLogger } from "bernie-client";
import { SystemEvent, SystemEventLevel } from "bernie-logger";

export const useOptimizeLodgingSearchLoadTimeExperiment = () => {
  const { exposure: preloadingExposure, logExperiment: logPreloadingExperiment } = useExperiment(
    "Optimise_lodging_search_load_time"
  );
  const logger = ClientLogger.getLoggerWithIdentifier("triggerLodgingPreloadingSearch");

  const { context } = useBexApi();
  const { executeMutation } = usePreloadPropertySearchMutation();

  const preloadingSearchResultsExperiment = React.useMemo(() => preloadingExposure.bucket, [preloadingExposure]);
  const preloadCallTriggerCount = React.useRef(0);
  const dateChangeCount = React.useRef(0);
  const roomsTravelerChangeCount = React.useRef(0);
  const destinationChangeCount = React.useRef(0);

  const shouldFetchPreloadingSearchResults = React.useMemo(
    () =>
      (preloadingSearchResultsExperiment === 1 && preloadCallTriggerCount.current < 2) ||
      (preloadingSearchResultsExperiment === 2 && preloadCallTriggerCount.current < 3),
    [preloadingSearchResultsExperiment, preloadCallTriggerCount.current]
  );

  const triggerMutation = async (mutationVariables: PrefetchSearchResultsMutationVariables) => {
    try {
      await executeMutation({ variables: mutationVariables });
    } catch (error) {
      logger.logEvent(
        new SystemEvent(SystemEventLevel.ERROR, "LODGING_PRELOADING_SEARCH_RESULTS_MUTATION_ERROR"),
        error
      );
    }
  };

  const triggerPreloadingSearch = React.useCallback(
    (field: Field, event: React.SyntheticEvent, queryParams: string) => {
      const hasDestinationFieldValue = new URLSearchParams(queryParams).get("destination") !== "";
      const searchCriteria = getPropertySearchCriteriaFromQueryParams(new URLSearchParams(queryParams))
        .propertySearchCriteriaInput;
      const mutationVariables = {
        context: context,
        criteria: searchCriteria,
        shoppingContext: { multiItem: null },
      };

      if (shouldFetchPreloadingSearchResults && hasDestinationFieldValue) {
        if (
          (field?.name === "EGDSDateRangePicker" || field?.name === "EGDSDateRangePicker-Flexible") &&
          dateChangeCount.current === 0
        ) {
          dateChangeCount.current++;
          preloadCallTriggerCount.current++;
          triggerMutation(mutationVariables as PrefetchSearchResultsMutationVariables);
        }
        if (field?.name === "EGDSRoomsTravelerSelector" && roomsTravelerChangeCount.current === 0) {
          roomsTravelerChangeCount.current++;
          preloadCallTriggerCount.current++;
          triggerMutation(mutationVariables as PrefetchSearchResultsMutationVariables);
        }
        if (
          field?.name === "destination_form_field" &&
          preloadingSearchResultsExperiment === 2 &&
          destinationChangeCount.current === 0
        ) {
          destinationChangeCount.current++;
          preloadCallTriggerCount.current++;
          triggerMutation(mutationVariables as PrefetchSearchResultsMutationVariables);
        }
      }
      try {
        if (hasDestinationFieldValue) {
          logPreloadingExperiment(preloadingExposure);
        }
      } catch {
        // noop
      }
    },
    [preloadingSearchResultsExperiment, dateChangeCount, roomsTravelerChangeCount, destinationChangeCount]
  );

  return {
    triggerPreloadingSearch,
    preloadCallTriggerCount,
    dateChangeCount,
    roomsTravelerChangeCount,
    destinationChangeCount,
  };
};
