import * as React from "react";
import { inject } from "mobx-react";
import { getBexApiContext } from "bernie-context";
import { UitkLayoutGrid, UitkLayoutGridItem } from "uitk-react-layout-grid";
import { PropertyRatesDateSelector } from "@shared-ui/lodging-property-rates-date-selector";
import { useRefContext } from "@shared-ui/ref-context";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { DateRangeInput } from "@shared-ui/retail-trips-providers";
import {
  DateFieldState,
  FormAction,
  FormActionProvider,
  ReferrerContext,
  TravelersField,
  TravelersFieldTriggerType,
  TravelersState,
} from "@shared-ui/lodging-form";
import { useTravelersFieldData } from "src/components/shared/DatePickerAndTravelerForm/TravelersFieldData";
import { UitkSpacing } from "@egds/react-core/spacing";
import { useSignalSubscriber } from "@shared-ui/signal-events-context";
import { PropertyOffersSignalNames } from "@shared-ui/lodging-property-offers";
import { END_DATE_REF_NAME, START_DATE_REF_NAME, queryBuilder } from "../utils";
import { IntersectionTracker } from "@shared-ui/clickstream-analytics-context";
import { useLocalization } from "@shared-ui/localization-context";

export const RoomAvailability: React.FC<{
  context: ExtendedContextStore;
}> = inject("context")(({ context }) => {
  const { registerTarget } = useRefContext();
  // Register targets so that datepicker can be opened from different parts of the page
  const startDateRef = registerTarget<HTMLButtonElement>(START_DATE_REF_NAME);
  const endDateRef = registerTarget<HTMLButtonElement>(END_DATE_REF_NAME);
  const [travelerDetails, setTravelerDetails] = React.useState("rm1=a2&");
  const CHECKIN = new Date();
  CHECKIN.setDate(CHECKIN.getDate());

  const dateRange: DateRangeInput = {
    end: { day: CHECKIN.getDate(), month: CHECKIN.getMonth() + 1, year: CHECKIN.getFullYear() },
    start: { day: CHECKIN.getDate(), month: CHECKIN.getMonth() + 1, year: CHECKIN.getFullYear() },
  };
  const travelersFieldData = useTravelersFieldData();
  const [toggleCalendar, setToggleCalendar] = React.useState(false);
  const { formatText } = useLocalization();

  useSignalSubscriber({
    query: { type: PropertyOffersSignalNames.RATE_CHECK_CTA_BUTTON_CLICK },
    onSignal: () => setToggleCalendar(true),
  });

  const onChange = (value: TravelersState) => {
    let travelDetail = "";
    value.rooms.map((room, index) => {
      const adults = "a" + room.adults;
      var childList = "";
      room?.children?.map((child) => {
        childList = childList += ":c" + child.age;
      });
      encodeURIComponent(childList);
      travelDetail = travelDetail + "rm" + (index + 1) + "=" + adults + encodeURIComponent(childList) + "&";
    });
    setTravelerDetails(travelDetail);
  };

  const onSubmitDateSelector = (startDate: Date, endDate: Date) => {
    const searchParams = new URLSearchParams(location.search);
    const additionalParameters = "&displayRate=true&" + travelerDetails + searchParams.toString();
    const url = queryBuilder(startDate, endDate, context, additionalParameters);
    setTimeout(function () {
      window.location.href = url;
    }, 0);
  };
  return (
    <ReferrerContextProvider>
      <ActionProvider onChange={onChange}>
        <IntersectionTracker linkName="RoomAvailabilityLink" referrerId="RoomAvailability.Impression">
          <UitkSpacing
            padding={{ blockend: "two", blockstart: "two" }}
            margin={{ small: { inline: "three" }, medium: { inline: "unset" } }}
          >
            <UitkLayoutGrid columns={{ small: 1, large: 2 }} rows={{ small: 2, large: 1 }} space="two">
              <UitkLayoutGridItem colSpan={{ small: 1, large: 2 }}>
                <PropertyRatesDateSelector
                  startDateRef={startDateRef}
                  endDateRef={endDateRef}
                  inputs={{
                    eid: String(context?.searchContext?.location.id),
                    dateRange,
                    context: getBexApiContext(context),
                  }}
                  inputLabel1={formatText("datePicker.fromDate.label")}
                  inputLabel2={formatText("datePicker.toDate.label")}
                  showCalendar={toggleCalendar}
                  toggleCalendar={setToggleCalendar}
                  onSubmit={onSubmitDateSelector}
                  hideDefaultDate
                  handleShape="rectangle"
                  showOnlyLowRates
                />
              </UitkLayoutGridItem>
              <UitkLayoutGridItem>
                <TravelersField
                  fieldName="travelers"
                  data={travelersFieldData}
                  closeOnSubmit={true}
                  trigger={TravelersFieldTriggerType.INPUT}
                />
              </UitkLayoutGridItem>
            </UitkLayoutGrid>
          </UitkSpacing>
        </IntersectionTracker>
      </ActionProvider>
    </ReferrerContextProvider>
  );
});
interface ActionProps {
  onChange: (value: TravelersState | DateFieldState) => void;
}

const ReferrerContextProvider: React.FC = ({ children }) => {
  const referrerContext = React.useMemo(() => ({ referrer: { pagePrefix: "HOT.HIS" } }), []);

  return <ReferrerContext.Provider value={referrerContext}>{children}</ReferrerContext.Provider>;
};
ReferrerContextProvider.displayName = "ReferrerContextProvider";

const ActionProvider: React.FC<ActionProps> = ({ onChange, children }) => {
  const dispatch = React.useMemo(
    () => (action: FormAction) => {
      switch (action.type) {
        case "change":
          onChange(action.value);
          break;
      }
    },
    [onChange]
  );

  return <FormActionProvider value={dispatch}>{children}</FormActionProvider>;
};
